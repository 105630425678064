import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';
import Filter5Icon from '@material-ui/icons/Filter5';
import Filter6Icon from '@material-ui/icons/Filter6';
import Filter7Icon from '@material-ui/icons/Filter7';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import { useFetch } from "hookFetchData";

const DJANGO_API_URL = process.env.REACT_APP_DJANGO_API_URL || "http://localhost:8000/"
const DJANGO_API_GET_TIMETABLE = process.env.REACT_APP_DJANGO_API_GET_TIMETABLE || "api/schedule/api/site-occurrences/?calendar_slug=casual-irk&timezone=Asia/Irkutsk"

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const weekday_name = {
  0: 'Пн',
  1: 'Вт',
  2: 'Ср',
  3: 'Чт',
  4: 'Пт',
  5: 'Сб',
  6: 'Вс',
}

const weekday_icons = {
  0: Filter1Icon,
  1: Filter2Icon,
  2: Filter3Icon,
  3: Filter4Icon,
  4: Filter5Icon,
  5: Filter6Icon,
  6: Filter7Icon,
}

export default function ProductSection(props) {
  const classes = useStyles();

  const [data, loading, nameError] = useFetch(
    DJANGO_API_URL + DJANGO_API_GET_TIMETABLE
  );

  const [classicModal, setClassicModal] = React.useState(false);
  const [appStartH, setAppStartH] = React.useState('');
  const [appStartD, setAppStartD] = React.useState('');

  function modalWithAppointment(hour, date) {
    setClassicModal(true);
    setAppStartH(hour);
    setAppStartD(date);
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h3 className={classes.title}>Запись на ближайшие дни</h3>

          {loading ? (
            <div className={classes.section} id="notifications">
              <SnackbarContent
                message={
                  <span>
                    <b>Загрузка:</b> Получаем данные от сервера...
                  </span>
                }
                close
                color="info"
                icon="info_outline"
              />
          </div>
        ) : (nameError) ? (
          <span><SnackbarContent
            message={
              <span>
                <b>Oops =(</b> Что-то пошло не так, данные не загрузились, попробуйте позднее...
              </span>
            }
            close
            color="warning"
            icon="info_outline"
          /></span>
        ) : (
            data.length != 0 ? (
              <NavPills
                alignCenter
                color="primary"
                tabs={
                  data.map(({ weekday, date, slots }) => (
                    {
                      tabButton: weekday_name[weekday] + " " + date,
                      tabIcon: weekday_icons[weekday],
                      tabContent:
                      (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            {slots[props.tagid - 1] ? (
                              slots[props.tagid - 1].map(( slot, i ) => (
                                <Button
                                  key={i}
                                  color="primary"
                                  size="sm"
                                  onClick={() => modalWithAppointment(slot['start'], date)}>
                                  {slot['start']}
                                </Button>
                              ))
                            ) : (
                              <h4 className={classes.title}>Пусто :( Но, возможно, администратор предложит решение (;</h4>
                            )
                          }

                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ))
                }
              />

            ) : (
              <SnackbarContent
                message={
                  <span>
                    <b>Oops:</b> Похоже, расписание еще не уточнили =(
                  </span>
                }
                close
                color="info"
                icon="info_outline"
              />
            )
          )}

          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={classicModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setClassicModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => setClassicModal(false)}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Переход к записи</h4>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}
            >
              <p>
                Вы уверены что хотите записаться на следующие время и дату: {appStartH}, {appStartD}?
              </p>
              <p>Останется сообщить нам только имя и номер, чтобы мы смогли подтвердить запись.</p>
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              <Link
                to={{pathname: "/call-me", appointmentinfo: {appStartH, appStartD, cabinettag:props.tagid}}}>
                <Button
                  color="primary"
                  simple>
                  Записаться
                </Button>
              </Link>
              <Button
                onClick={() => setClassicModal(false)}
                color="danger"
                simple
              >
                Отменить
              </Button>
            </DialogActions>
          </Dialog>

        </GridItem>
      </GridContainer>
    </div>
  );
}
