const pages_titles_n_indexes_dict = {
  1: 'beautician',
  2: 'massage',
  // 3: 'face-cleaning',
  3: 'depilation',
  4: 'eyebrow-&-eyelash',
  5: 'nail-bar',
  6: 'hairdresser',
  7: 'makeup',
  8: 'cedar-barrel-&-solarium',
  'beautician': 1,
  'massage': 2,
  // 'face-cleaning': 3,
  'depilation': 3,
  'eyebrow-&-eyelash': 4,
  'nail-bar': 5,
  'hairdresser': 6,
  'makeup': 7,
  'cedar-barrel-&-solarium': 8,
}

const titles_rus = {
  1: 'Кабинет косметики',
  2: 'Массажный кабинет',
  // 3: 'Чистка лица',
  3: 'Депиляция',
  4: 'Оформление бровей и ресниц',
  5: 'Кабинет маникюра и педикюра',
  6: 'Парикмахерский зал',
  7: 'Макияж',
  8: 'Солярий',
}

const max_offer_page_index = Object.keys(titles_rus).length;

export { pages_titles_n_indexes_dict, max_offer_page_index, titles_rus };
