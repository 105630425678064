import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/1/logo.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection1() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Аппаратная мезотерапия лица</h3>
          <Muted>
            Мезотерапия лица – это универсальный способ, позволяющий решить большинство эстетических проблем.
            <br/><br/>
            Процедура отлично справляется с сухостью и обезвоженностью кожи, помогает избавиться от темных пятен и морщин, а также от акне и различных эстетических дефектов
          </Muted>
        </GridItem>

      </GridContainer>
    </div>
  );
}
