import React from "react";
import {Helmet} from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { titles_rus } from "components/Pagination/PaginationDict.js"

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function TitleSection(props) {
  const classes = useStyles();

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div className={classes.section}>
    <Helmet>
        <title>Профессиональная услуга: {titles_rus[props.titleid]}.</title>
        <meta name="description" content={"Все подробности об оказываемой услуге: " + titles_rus[props.titleid] + ". Узнайте подробнее о каждом этапе, ценах и свободном для записи времени."} />
    </Helmet>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{titles_rus[props.titleid]}</h2>
        </GridItem>
      </GridContainer>
    </div>
  );
}
