import React, { useState, useEffect } from "react";
import {Helmet} from 'react-helmet';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// react plugin for creating date-time-picker
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { titles_rus } from "components/Pagination/PaginationDict.js"

import Check from "@material-ui/icons/Check";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Warning from "@material-ui/icons/Warning";
import InputLabel from "@material-ui/core/InputLabel";


import isMobilePhone from 'validator/es/lib/isMobilePhone';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg4.jpg";
// 4 calendar-picker
var moment = require('moment');
require('moment/locale/ru');

const DJANGO_API_URL = process.env.REACT_APP_DJANGO_API_URL || "http://localhost:8000/"
const API_CALL_ME = process.env.REACT_APP_API_CALL_ME || "api/mascow/callme"

const useStyles = makeStyles(styles);

function SuccessSend(props) {
  const form = (
    <SnackbarContent
      message={
        <span>
          <b>{props.title}</b> {props.text}
        </span>
      }
      close
      color="success"
      icon={Check}
    />
  )
  return form;
}
function DangerSend(props) {
  const form = (
    <SnackbarContent
      message={
        <span>
          <b>{props.title}</b> {props.text}
        </span>
      }
      close
      color="danger"
      icon="info_outline"
    />
  )
  return form;
}
function WarningSend(props) {
  const form = (
    <SnackbarContent
      message={
        <span>
          <b>{props.title}</b> {props.text}
        </span>
      }
      close
      color="warning"
      icon={Warning}
    />
  )
  return form;
}

export default function CallMe(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  // user data
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState('+79250143223');

  // errors
  const [isSent, setIsSent] = useState(false);
  const [isError, setError] = useState(false);
  const [isMetaError, setMetaError] = useState(false);
  const [isBadUser, setBadUser] = useState(false);
  const [isWarn, setWarn] = useState(false);
  const [isNotTime, setNotTime] = useState(false);
  // feedback of user's filed
  const [nameError, setNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [datePicker, setDatePicker] = useState();

  const cur_date = new Date().toLocaleTimeString('ru-Ru', { timeZone: 'Asia/Irkutsk', hour12: false });

  useEffect(() => {
    NameValidation()
    PhoneValidation()
  })

  const NameValidation = () => {
    if (name === "") {
      setNameError(true)
      return true
    } else {
      setNameError(false)
      return false
    }
  }

  const validatePhoneNumber = (number) => {
   const isValidPhoneNumber = isMobilePhone(number, ['ru-RU'])
   return (isValidPhoneNumber)
  }

  const PhoneValidation = () => {
    if (!validatePhoneNumber(phone)) {
      setPhoneError(true)
      return true
    } else {
      setPhoneError(false)
      return false
    }
  }

  let app_time_2_post = '';
  const submitData = e => {
  e.preventDefault()
  if (isSent) {
      setWarn(true);
      setIsSent(false);
    } else {
      if ((nameError) || (phoneError) || (phone=='+79250143223')) {
        return
      } else {
        if (datePicker) {app_time_2_post = datePicker} else if (appDefTime) {app_time_2_post = appDefTime} else {app_time_2_post=''}
        fetch(DJANGO_API_URL + API_CALL_ME, {
                method: 'post',
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({name, email, phone, app_time_2_post, tagid})
              }).then(function(response) {
                    if (response.status == 400) {
                      setMetaError(true);
                    }
                    if (response.status == 403) {
                      setBadUser(true);
                    }
                    if (response.status == 406) {
                      setNotTime(true);
                    }
                    if (response.status > 400 && response.status < 600 && response.status != 403 && response.status != 406 ) {
                      setError(true);
                    }
                    if (response.status == 201) {setIsSent(true)}
                    return response.json();
                  })
                  .catch(() => setError(true));
          setNotTime(false);
          setWarn(false);
          setBadUser(false);
          setMetaError(false);
          setError(false);
      }}
  }

  var yesterday = Datetime.moment().subtract( 1, 'day' );
  var valid_calendar = function( current ){
      return current.isAfter( yesterday );
  };

  function handleDate(date){
   setDatePicker(date);
  };

  let tagid = '';
  let appDefTime = '';
  if (props.location.appointmentinfo) {
    const day_t = parseInt(props.location.appointmentinfo.appStartD.substring(0,2));
    const month_t = parseInt(props.location.appointmentinfo.appStartD.substring(3,5));
    const hour_t = parseInt(props.location.appointmentinfo.appStartH.substring(0,2));
    const min_t = parseInt(props.location.appointmentinfo.appStartH.substring(3,5));
    appDefTime = new Date(new Date().getFullYear(), month_t, day_t, hour_t, min_t);
    tagid = parseInt(props.location.appointmentinfo.cabinettag);
  };

  return (
    <div>
      <Helmet>
          <title>Запись в салон Casual</title>
          <meta name="description" content="Воспользуйтесь удобной формой записи в салон красоты Casual на Байкальской 160А." />
      </Helmet>
      <Header
        absolute
        color="transparent"
        brand="Салон Красоты Casual"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Запись в салон</h4>
                  </CardHeader>
                  {isSent ? (
                    <SuccessSend title="Yonked!)" text="Сейчас Вам позвоним для записи." />
                  ) : (
                    null
                  )}
                  {isNotTime ? (
                    <WarningSend title="Ooops =(" text={"К сожалению сейчас (" + cur_date +") мы не работаем..."} />
                  ) : (
                    null
                  )}
                  {isMetaError ? (
                    <DangerSend text="Проверьте корректность введенных данных." title="Not Yonked =("/>
                  ) : (
                    null
                  )}
                  {isBadUser ? (
                    <WarningSend title="Ooops =(" text="К сожалению слишком много запросов за последнее время..." />
                  ) : (
                    null
                  )}
                  {isError ? (
                    <DangerSend text="Что-то пошло не так, - попробуйте позднее." title="Not Yonked =("/>
                  ) : (
                    null
                  )}
                  {isWarn ? (
                    <WarningSend title="Not Yonked =(" text="Вы уже заказали 1 звонок, уверены, что хотите отправить форму снова?" />
                  ) : (
                    null
                  )}
                  <p className={classes.divider}>
                    Можете оставить только имя & номер, и мы Вам перезвоним.
                  </p>
                  <CardBody>

                    {appDefTime ? (
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                          <InputLabel className={classes.label}>
                            Дата & Время
                          </InputLabel>
                          <FormControl fullWidth>
                            <Datetime
                              isValidDate={ valid_calendar }
                              inputProps={{ placeholder: "Нажмите, чтобы выбрать"}}
                              onChange={(e) => handleDate(e._d)}
                              defaultValue={appDefTime}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <InputLabel className={classes.label}>
                            Услуга
                          </InputLabel>
                          <Datetime
                            dateFormat={false}
                            timeFormat={false}
                            inputProps={{ placeholder: titles_rus[tagid], disabled: true}}
                          />
                        </GridItem>
                      </GridContainer>
                    ) : (null)}

                    <CustomInput
                      labelText="Имя..."
                      id="first"
                      error={nameError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setName(e.target.value),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="E-mail..."
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setEmail(e.target.value),
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Телефон..."
                      id="first"
                      error={phoneError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setPhone(e.target.value),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <PhoneIphoneIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />

                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      size="lg"
                      onClick={submitData}
                    >
                      Отправить
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
