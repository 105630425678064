import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import Paginations from "components/Pagination/Pagination.js";


import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function PaginationSection(props) {
  const classes = useStyles();

  const current_page = props.desire_page;
  const pages_text = [
    { text: Number(current_page)-2 },
    { text: Number(current_page)-1 },
    { active: true, text: Number(current_page) },
    { text: Number(current_page)+1 },
    { text: Number(current_page)+2 },
  ];

  function correct_pages() {
    if (pages_text[2].text == 2) {
      return (pages_text.slice(1,));
    }
    if (pages_text[2].text == 1) {
      return (pages_text.slice(2,));
    }
    if (pages_text[2].text == 9) {
      return (pages_text.slice(0,3));
    }
    if (pages_text[2].text == 8) {
      return (pages_text.slice(0,4));
    }

    return (pages_text);
  }

  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div className={classes.space70} />
        <div id="progress">
          <GridContainer justify="center" >
              <Paginations
                pages={correct_pages()}
                color="primary"
              />
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
