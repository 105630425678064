import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/5/1.jpg";
import img2 from "assets/img/offers/5/2.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection5() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Коррекция бровей и ресниц</h3>
          <Muted>
          Форма бровей может кардинально изменить образ. Зачастую брови растут слишком густо или, наоборот, слишком редко и тонко, а хотелось бы достичь золотой середины. Именно в таком случае мастер проводит коррекцию бровей – убирает лишнее, подчеркивая необходимое.
          <br/><br/>

          Есть определенные каноны, как выбрать форму бровей исходя из формы лица, глаз и других индивидуальных особенностей. Часто, экспериментируя самостоятельно, девушки лишь создают себе новый повод для похода к косметологу, а профессионалу потом приходится исправлять ошибки и недочеты.
          <br/><br/>

          В нашем салоне вы сможете получить профессиональный уход за бровями, а наши специалисты создадут идеальную форму бровей, которая будет подчеркивать ваш взгляд.

          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Окрашивание ресниц</h3>
          <Muted>
          Окрашивание краской эффективный способ на длительный срок изменить естественный цвет ресниц. Обычно волоски делают более тёмными, – глаза в результате становятся ярче и выразительнее.
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img2}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

      </GridContainer>
    </div>
  );
}
