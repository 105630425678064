import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

//  my imports
import studio1 from "assets/img/studio/inside1.jpg";
import studio2 from "assets/img/studio/inside2.jpg";
import studio3 from "assets/img/studio/inside3.jpg";
import studio4 from "assets/img/studio/inside4.jpg";
import studio5 from "assets/img/studio/inside5.jpg";

import neils1 from "assets/img/offers/6/1.jpeg";
import neils2 from "assets/img/offers/6/2.jpeg";
import neils3 from "assets/img/offers/6/3.jpeg";
import neils5 from "assets/img/offers/6/4.jpeg";


import stile1 from "assets/img/stile1.jpg";
import stile2 from "assets/img/stile2.jpg";
import stile3 from "assets/img/stile3.jpg";
import stile4 from "assets/img/stile4.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
          <NavPills
            alignCenter
            color="primary"
            tabs={[
              {
                tabButton: "Студия",
                tabIcon: Camera,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={studio4}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={studio1}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={studio5}
                        className={navImageClasses}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={studio3}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={studio2}
                        className={navImageClasses}
                      />
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: "Маникюр",
                tabIcon: Palette,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={neils1}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={neils2}
                        className={navImageClasses}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={neils5}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={neils3}
                        className={navImageClasses}
                      />
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: "Парикмахерская",
                tabIcon: Favorite,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={stile1}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={stile2}
                        className={navImageClasses}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={stile3}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={stile4}
                        className={navImageClasses}
                      />
                      {/* <img
                        alt="..."
                        src={studio1}
                        className={navImageClasses}
                      /> */}
                    </GridItem>
                  </GridContainer>
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
