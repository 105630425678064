import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/2/logo.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection2() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Классический массаж</h3>
          <Muted>
          Массаж — это не только удовольствие, но и процедура, которая дарит большую пользу организму: от кожного покрова до систем пищеварения и кровообращения.
          </Muted>
        </GridItem>

      </GridContainer>
    </div>
  );
}
