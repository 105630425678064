import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/9/1.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection5() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="Солярий в Иркутске"
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Солярий</h3>
          <Muted>
          Как правильно загорать впервые, сколько загорать в солярии, как часто ходить и что нужно.
          <br/><br/>
          Ниже мы собрали основные советы для тех, кто никогда не проходил процедуру, а также и информацию для тех, кто регулярно пользуется солярием (;
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br/></GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>Как загорать в солярии?</h3>
          <Muted>
          Впервые отправляясь в солярий, важно позаботиться о подготовке тела к процедуре и максимально обеспечить его безопасность. Для этого необходимо выполнять следующие правила:
          <ol>
            <li>За день до использования солярия воспользуйтесь для тела;</li>
            <li>Обработайте тело специальной косметикой, стимулирующей выработку меланина. Не используйте солнцезащитные средства для принятия солнечных ванн на пляже, поскольку они блокируют доступ ультрафиолетовых лучей к коже;</li>
            <li>Необходимо защитить соски груди, потому что кожа на них очень нежная и чувствительная. Для этого потребуется стикини — наклейки, защищающие грудь. То же самое относится к родинкам или другим поражениям кожи;</li>
            <li>Перед процедурой выясните, дезинфицируется ли солярий, работает ли вентиляция и есть ли возможность выключить лампы для загара. Также проверьте срок службы лампы. Первые 80 часов люминесцентные лампы работают слишком усердно и сокращают время загара. Если они работают более 800 часов, откажитесь от кабины вообще;</li>
            <li>Снимите контактные линзы и украшения;</li>
            <li>В солярии интенсивно потеют, поэтому после загара пейте много воды, чтобы восполнить потерю.</li>
          </ol>
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>Сколько загорать?</h3>
          <Muted>
          Процедуру начинают с 5-10-минутных сеансов (в зависимости от цвета лица, типа и количества ламп), постепенно увеличивая его до 30 минут. Пара замечаний:

          <ol>
          <li>Не загорайте каждый день (1-2 дня в неделю). Процедура займет всего 6-10 сеансов, после чего следует сделать более длительный перерыв;</li>
          <li>Безопасно загорать около 10 часов в год, и это касается солярия в салоне и солнышка на пляже.</li>
          </ol>
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>Как быстро загореть?</h3>
          <Muted>
          Для быстрого загара необходимо выбирать вертикальные солярии, которые оборудованы мощными ультрафиолетовыми лампами. Лучи лучше воздействуют на чистую кожу, поэтому перед посещением солярия важно применить скраб. Также время процедуры сократят бронзатор, увлажняющая и очищающая косметика.
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>Как часто ходить?</h3>
          <Muted>
          Если человек только начинает пользоваться солярием, нужно повторять сеансы каждые 2-3 дня, но не более 10 дней. Затем используйте солярий однократно в неделю. Каждые два или три месяца делайте перерыв на четыре недели.
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>Можно ли ходить в солярий каждый день?</h3>
          <Muted>
          Если человек только начинает пользоваться солярием, нужно повторять сеансы каждые 2-3 дня, но не более 10 дней. Затем используйте солярий однократно в неделю. Каждые два или три месяца делайте перерыв на четыре недели.
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>Что нужно для солярия (аксессуары & косметика)?</h3>
          <Muted>
          Необходимые защитные аксессуары и косметика:
          <ol>
            <li><b>Крем</b> - для защиты кожи в солярии необходимо наносить перед процедурой специальный крем с SPF-защитой и тингл-эффектом. Средства увлажняют слои ткани, а также содействуют сохранению загара на долгое время.</li>
            <li><b>Крем после солярия</b> - Сразу после окончания процедуры важно нанести на тело крем на основе эфирных масел. Подойдут средства с увлажняющим и регенерирующим эффектом.</li>
            <li><b>Масло</b> - для солярия лучше приобретать специальные, созданные именно для данной процедуры масла или натуральные средства. Косметические масла могут привести к ожогам и изменению пигментации. Средство наносят до и после загара.</li>
            <li><b>Очки</b> - часто этим аксессуаром пренебрегают, однако наличие очков важно для сохранения здоровья глаз и кожи век. Их отсутствие при загаре может сказаться на остроте зрения, вызвать раздражение. Очки обычно выдают в солярии.</li>
            <li><b>Стикини</b> - cпециальные накожные наклейки защищают соски, родинки и родимые пятна от чрезмерного воздействия ультрафиолета. Стикини снижают риск развития меланомы и защищают чувствительные участки кожи.</li>
          </ol>
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>Польза солярия?</h3>
          <Muted>
          Каждая косметическая процедура несет определенную пользу для для здоровья тела: cамым большим преимуществом посещения солярия является пополнение запасов витамина D, без которого детям угрожают рахит, пожилые люди могут столкнуться с остеопорозом и кариесом.
          Суточная доза этого витамина у детей и взрослых составляет 400 МЕ. Организм не способен вырабатывать такое количество витамина D, поэтому его следует обеспечивать профилактически. Поэтому, если контакт с солнцем является непостоянным, нужно отправиться в солярий с целью синтеза витамина D.
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

      </GridContainer>
    </div>
  );
}
