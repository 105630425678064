import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import img1 from "assets/img/offers/6/1.jpeg";
import img2 from "assets/img/offers/6/2.jpeg";
import img3 from "assets/img/offers/6/3.jpeg";
import img4 from "assets/img/offers/6/4.jpeg";
import img5 from "assets/img/offers/6/5.jpeg";
import img6 from "assets/img/offers/6/6.jpeg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

const imgs = [img1, img3, img2, img4, img5, img6];

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                {imgs.map((image, idx) =>
                  <div>
                    <img src={image} alt={"Photo of nails No. " + idx} className="slick-image" />
                  </div>
                )}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
