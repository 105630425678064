import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="На связи с Вами"
              description="По любым вопросам Вы всегда можете смело обратиться к нашим администраторам, будь то подбор мастера или тарифа, через соц. сеть или форму записи ;)"
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Актуальность цен"
              description="Этот прайс-лист редактируется нами напрямую, - можете смело сохранить себе ссылку. К примеру, для Chrome на Android: (три точки) -> (добавить на главный экран)"
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Система лояльности"
              description="Наши менеджеры регулярно обновляют цены и скидки прямо во всем web-приложении, но об отдельных же программах лояльности для постоянных клиентов мы уведомляем через мессенджеры. С заботой о Вас, Casual (:"
              icon={LoyaltyIcon}
              iconColor="rose"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
