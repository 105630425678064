/*eslint-disable*/
import React from "react";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { HashLink as Link } from 'react-router-hash-link';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, ReceiptOutlined, PermPhoneMsg } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

//  user imports
import SectionOffers from "views/Components/Sections/SectionOffers.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Меню"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/"
              className={classes.dropdownLink}>
              Главная
            </Link>,
            <Link to="/#offers"
              className={classes.dropdownLink}>
              Услуги
            </Link>,
            <Link to="/#about"
              className={classes.dropdownLink}>
              О салоне
            </Link>,
            <Link to="/#discounts"
              className={classes.dropdownLink}>
              Скидки & Акции
            </Link>,
            <Link to="/#sign-up" className={classes.dropdownLink}>
              Как записаться
            </Link>,
            <Link to="/#contact-&-map" className={classes.dropdownLink}>
              Контакты & Карта
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/price-list"
          color="transparent"
          className={classes.navLink}
        >
          <ReceiptOutlined className={classes.icons} />Прайс-лист
        </Link>
        <Link to="/call-me"
          color="transparent"
          className={classes.navLink}
        >
          <PermPhoneMsg className={classes.icons} />Записаться
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Instagram салона Casual"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/saloncasualirk/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Запись по WhatsApp"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://wa.me/79647398810"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-whatsapp"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
