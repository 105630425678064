import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";

// fetch notifs
import { useFetch } from "hookFetchData";

const useStyles = makeStyles(styles);

const DJANGO_API_URL = process.env.REACT_APP_DJANGO_API_URL || "http://localhost:8000/"
const API_NOTIFICATIONS = process.env.REACT_APP_API_NOTIFICATIONS || "api/mascow/fetch/notifications"

export default function SectionNotifications4Users() {
  const classes = useStyles();

  const [data, loading, nameError] = useFetch(
    DJANGO_API_URL + API_NOTIFICATIONS
  );

  return (
    <div className={classes.section} id="notifications">
      {(() => {
        if (loading) {
          return (
            <div>
              <SnackbarContent
              message={
                <span>
                  <b>Загрузка уведомлений:</b> Получаем данные от сервера...
                </span>
              }
              close
              color="info"
              icon="info_outline"
            />
          </div>
          )
        } else if (nameError) {
          return (
              <SnackbarContent
              message={
                <span>
                  <b>Oops =(</b> Что-то пошло не так, уведомления не загрузились, попробуйте позднее...
                </span>
              }
              close
              color="warning"
              icon="info_outline"
            />
          )
        } else {
          return (
            <div className={classes.section} id="notifications">
              {data.map(({ pk, title, text4user, type }) => (
                <SnackbarContent
                  key={pk}
                  message={
                    <span>
                      <b>{title}:</b> {text4user}
                    </span>
                  }
                  close
                  color={type}
                  icon={(type == 'info') || (type == 'danger') ? 'info_outline' : type == 'warning' ? Warning : Check}
                />
              ))}
            </div>
          )
        }
      })()}
      <Clearfix />
    </div>
  );
}
