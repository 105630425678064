import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RoomIcon from '@material-ui/icons/Room';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import SectionPartners from "./SectionPartners.js";
//  my imports
import TimeTable from "components/Table/TimeTable.js"
import YandexMap from "components/YandexMap/YandexMap.js"
import { primaryColor } from "assets/jss/material-kit-react.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  return (
    <div id="contact-&-map" className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
          <NavPills
            alignCenter
            color="primary"
            tabs={[
              {
                tabButton: "Режим работы",
                tabIcon: AccessTimeIcon,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                      <TimeTable />
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: "Как добраться",
                tabIcon: RoomIcon,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                      <YandexMap />
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: "Контакты",
                tabIcon: ListAltIcon,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                          <h3 className={classes.title}>Адреса & Телефоны</h3>
                          <div className={classes.typo}>
                            <h5><PhoneIphoneIcon style={{fontSize:12}}/><a style={{color:primaryColor}} href='tel:+73952590070'> +7 (3952) 59-00-70</a></h5>
                            <h5><PhoneIphoneIcon style={{fontSize:12}}/><a style={{color:primaryColor}} href='tel:+79647398810'> +7 964-739-88-10</a></h5>
                            <h5><EmailIcon style={{fontSize:12}}/><a style={{color:primaryColor}} href='mailto:salon@casual-salon.ru'> salon@casual-salon.ru</a></h5>
                          </div>
                          <div className={classes.typo}>
                            <h5><RoomIcon style={{fontSize:12}}/><a style={{color:primaryColor}} href='https://search.google.com/local/writereview?placeid=ChIJj97skmI7qF0RFtogsJSONZ8' target="_blank">Иркутск ул. Байкальская, 160а</a></h5>
                          </div>
                          <Button
                            justIcon
                            link
                            className={classes.margin5}
                            href="https://www.instagram.com/saloncasualirk/"
                            target="_blank"
                            >
                            <i className={"fab fa-instagram"} />
                          </Button>
                          <Button
                            justIcon
                            link
                            className={classes.margin5}
                            href="https://wa.me/79647398810"
                            target="_blank"
                            >
                            <i className={"fab fa-whatsapp"} />
                          </Button>
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: "Партнерство",
                tabIcon: GroupAddIcon,
                tabContent: (
                  <SectionPartners />
                )
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
