import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/completedStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTexContactUs() {
  const classes = useStyles();
  return (
    <div id="sign-up" className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>Готовы записаться?</h2>
            <h4>
              Наши мастера несомненно радуют наших клиентов!
              Высокое качество материалов и квалификация мастеров не оставит никого равнодушным.
              <br/>
              Если остались вопросы/готовы записаться: можете позвонить сами или заказать обратный звонок через форму записи (;
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <Link to={"/call-me"} className={classes.link}>
              <Button color="primary">Перейти к записи</Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
