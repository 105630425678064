import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import LoupeIcon from '@material-ui/icons/Loupe';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import StarRateIcon from '@material-ui/icons/StarRate';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import image1 from "assets/img/sales/1.jpg";
import image2 from "assets/img/sales/2.jpg";
import image3 from "assets/img/sales/3.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionSales() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={12} lg={12}>
              <NavPills
                color="rose"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 4, md: 4 }
                }}
                tabs={[
                  {
                    tabButton: "Сертификат",
                    tabIcon: StarRateIcon,
                    tabContent: (
                      <span>
                          <img
                            style={{height: "300px", width: "auto", display: "block", margin: "-10px"}}
                            className={classes.imgCardTop}
                            src={image1}
                            alt="Card-img-cap"
                          />
                            <h4 className={classes.cardTitle}>Подарочный сертификат</h4>
                            <p>
                              Подарочный сертификат для Ваших близких
                            </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Накопительная",
                    tabIcon: LoupeIcon,
                    tabContent: (
                      <span>
                          <img
                            style={{height: "300px", width: "auto", display: "block", margin: "-10px"}}
                            className={classes.imgCardTop}
                            src={image2}
                            alt="Card-img-cap"
                          />
                            <h4 className={classes.cardTitle}>Накопительная карта -15%</h4>
                            <p>
                              Гибкая система накопления несгорающих бонусов.
                            </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Бонус-карта",
                    tabIcon: TrendingDownIcon,
                    tabContent: (
                      <span>
                          <img
                            style={{height: "300px", width: "auto", display: "block", margin: "-10px"}}
                            className={classes.imgCardTop}
                            src={image3}
                            alt="Card-img-cap"
                          />
                            <h4 className={classes.cardTitle}>Одноразовая скидка</h4>
                            <p>
                              Скидка на услуги в салоне -10%
                            </p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
